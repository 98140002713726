import SocialLinks from './social_links';
import Banner from './banner';
import TextSectionVerical from './text_section_vertical';
import TextSectionHorizontal from './text_section_horizontal';
import PledgeForm from './pledge_form';
import FAQ from './faq';
import Partners from './partners';
import CompanyStatement from './company_statement';
import ContactForm from './contact_form';
import SkyAnthem from './sky_anthem';
import PreviousIssues from '../previousIssues';
import TextSection from './text_section';
import ImageSection from './image_section';
import PledgeFormFR from './pledge_form _fr';
import ContactFormFR from './contact_form_fr';
import List from './list';
import Title from './title';
import Divider from './divider';
import PageSEO from './seo';
import ImageListItem from './image_list_item';
import Card from './card';
import CardList from './cardList';
import RecommendedArticles from './recommendedArticles';
import MagazineSummarry from './magazineSummarry';
import CustomDivider from './customDivider';
import Testimonials from './testimonials';
import MagazinesList from './magazines';
import VideoPlaylist from './videoPlaylist';
import Event from './event';
import EventVerify from './eventVerify';
import EventTicket from './eventTicket';
import ConsentForm from './consentForm';
import DramaBanner from './dramaBanner';
import DramaDescrption from './dramaDescription';
import DramaVideoPlaylist from './dramaVideoPlaylist';
import Slider from './slider';
import SKYHub from './SKYHub';
import Hosts from './hosts';
import Gallery from './gallery';
import BirthdayEvent from './BirthdayEvent';
import BirthdayConsentForm from './BirthdayconsentForm';

let componentList = {
  banner: {
    name: 'Banner',
    component: Banner,
  },

  text_section_horizontal: {
    name: 'Text Section Horizontal',
    component: TextSectionHorizontal,
  },
  text_section_vertical: {
    name: 'Text Section Vertical',
    component: TextSectionVerical,
  },
  social_links: {
    name: 'Social links',
    component: SocialLinks,
  },

  sky_anthem: {
    name: 'Sky Anthem',
    component: SkyAnthem,
  },
  latest_magazine_summarry: {
    name: 'Latest Magazines Summarry',
    component: MagazineSummarry,
  },
  previous_magazines: {
    name: 'Previous Magazines',
    component: PreviousIssues,
  },
  pledge_form: {
    name: 'Pledge Form',
    component: PledgeForm,
  },
  pledge_form_fr: {
    name: 'Pledge Form',
    component: PledgeFormFR,
  },
  contact_form: {
    name: 'Contact Form',
    component: ContactForm,
  },
  contact_form_fr: {
    name: 'Contact Form',
    component: ContactFormFR,
  },
  faqs: {
    name: 'FAQs',
    component: FAQ,
  },
  // contact_details: {
  //   name: 'Contact Details',
  //   component: ContactDetails,
  //   content: {
  //     type: 'contact_details',
  //     name: null,
  //     location: null,
  //     phone: null,
  //     email: null,
  //   },
  // },

  partners: {
    name: 'Partners',
    component: Partners,
    content: {
      title: null,
      content: null,
      type: 'partners',
    },
  },
  company_statement: {
    name: 'Company Statement',
    component: CompanyStatement,
  },
  text_section: {
    name: 'Text Section',
    component: TextSection,
  },
  image_section: {
    name: 'Image Section',
    component: ImageSection,
  },
  list: {
    name: 'List Item',
    component: List,
  },
  title: {
    name: 'Title Item',
    component: Title,
  },
  divider: {
    name: 'List Divider',
    component: Divider,
  },
  seo: {
    name: 'SEO',
    component: PageSEO,
  },
  imagelist: {
    name: 'Image List Item',
    component: ImageListItem,
  },

  card: {
    name: 'Card',
    component: Card,
  },
  cardlist: {
    name: 'Card List',
    component: CardList,
  },
  recommendedarticles: {
    name: 'Recommended Articles',
    component: RecommendedArticles,
  },
  custom_divider: {
    name: 'custom_divider',
    component: CustomDivider,
  },
  testimonials: {
    name: 'testimonials',
    component: Testimonials,
  },
  magazines: {
    name: 'magazines',
    component: MagazinesList,
  },
  videoPlaylist: {
    name: 'videoPlaylist',
    component: VideoPlaylist,
  },
  event: {
    name: 'event',
    component: Event,
  },
  birthday_event: {
    name: 'birthday_event',
    component: BirthdayEvent,
  },
  eventVerify: {
    name: 'eventVerify',
    component: EventVerify,
  },
  eventTicket: {
    name: 'eventTicket',
    component: EventTicket,
  },
  consentForm: {
    name: 'consentForm',
    component: ConsentForm,
  },
  birthdayConsentForm: {
    name: 'birthdayConsentForm',
    component: BirthdayConsentForm,
  },
  drama_banner: {
    name: 'drama_banner',
    component: DramaBanner,
  },
  drama_description: {
    name: 'drama_description',
    component: DramaDescrption,
  },
  drama_video_playlist: {
    name: 'drama_video_playlist',
    component: DramaVideoPlaylist,
  },
  slider: {
    name: 'slider',
    component: Slider,
  },
  SKYHub: {
    name: 'SKYHub',
    component: SKYHub,
  },
  hosts: {
    name: 'hosts',
    component: Hosts,
  },
  gallery: {
    name: 'gallery',
    component: Gallery,
  },
};

export default componentList;
