import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation } from 'swiper/core';
import { ChevronDown, ChevronUp, Copy } from 'react-feather';

import BannerComponent from './bannerComponent';
import AgePicker from '../agePicker';

SwiperCore.use([Navigation]);

function BirthdayEvent({ cards = [], faqs = [] }) {
  // Local states
  const [calendarIndex, setCalendarIndex] = useState(0);
  const [activeFAQ, setActiveFAQ] = useState(0);

  const [showAgeLimitPopup, setShowAgeLimitPopup] = useState(false);
  const [showGenderPopup, setShowGenderPopup] = useState(false);
  const [showMonthPopup, setShowMonthPopup] = useState(false);

  // Whether to show the "share with parent" block
  const [showShareBlock, setShowShareBlock] = useState(false);

  const [inputs, setInputs] = useState({
    dob: null,
    gender: '',
  });

  const [bookingData, setBookingData] = useState({});

  // Initialize Swiper & fetch data on mount
  useEffect(() => {
    fetchBookingData();

    const swiperInstance = new Swiper('.swiper-container', {
      speed: 500,
      spaceBetween: 30,
      navigation: {
        nextEl: '.nav-right',
        prevEl: '.nav-left',
      },
    });

    swiperInstance.on('slideChange', (data) => {
      setCalendarIndex(data.activeIndex);
    });
  }, []);

  // Fetch booking data (if needed) from your API
  const fetchBookingData = () => {
    window
      .fetch(`${global.server}/booking-data/3`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((response) => {
        setBookingData(response);
      })
      .catch(() => {});
  };

  // Handle date changes from AgePicker
  const handleDOBChange = (date) => {
    setInputs((prev) => ({ ...prev, dob: date }));
  };

  // On blur, check if out of range (under 12 or over 19) => show popup
  // const handleDOBBlur = (date) => {

  // };

  // Handle gender selection
  const handleGenderChange = (e) => {
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, gender: value }));

    // If 'male' => show gender popup
    if (value === '2') {
      setShowGenderPopup(true);
    } else {
      setShowGenderPopup(false);
    }
  };

  // Form Submission: Check age + route or show share block
  const handleSubmit = (e) => {
    const age = moment().diff(inputs.dob, 'years');

    if (age > 19 || age < 12) {
      setShowAgeLimitPopup(true);
    } else {
      setShowAgeLimitPopup(false);
    }

    // if (moment(inputs.dob).month() !== 3) {
    //   setShowMonthPopup(true);
    // }

    e.preventDefault();
    if (!inputs.dob) {
      alert('Please enter Your Date of Birth');
      return;
    }

    // const age = moment().diff(inputs.dob, 'years');

    // If user out of range or male, do nothing (they see popups)
    if (showAgeLimitPopup || showGenderPopup) return;

    if (age >= 18 && age <= 19) {
      // 18 or 19 => adult route
      window.location.href = '/sky-hub-consent?dob=' + inputs.dob;
    } else if (age >= 12 && age < 18) {
      // Underage => show share block
      setShowShareBlock(true);
    }
    // If user is under 12 or over 19, the showAgeLimitPopup is already displayed
  };

  // Copy link for /parental-consent-form
  const copyLink = () => {
    const link = `${window.location.origin}/sky-hub-parental-consent?dob=${inputs.dob}`;

    // Check if the Clipboard API is supported
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(link)
        .then(() => alert('Link copied to clipboard!'))
        .catch((err) => {
          console.error('Clipboard API failed:', err);
          fallbackCopy(link); // Use fallback
        });
    } else {
      fallbackCopy(link); // Use fallback if Clipboard API is not supported
    }
  };

  // Fallback function for copying text
  const fallbackCopy = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);

    textarea.select();
    textarea.setSelectionRange(0, text.length); // For mobile compatibility

    try {
      document.execCommand('copy');
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Fallback copy failed:', err);
      alert('Failed to copy link.');
    }

    document.body.removeChild(textarea); // Clean up
  };

  // Share link: tries native share, fallback to WhatsApp
  const shareLink = () => {
    const link = `${window.location.origin}/sky-hub-parental-consent?dob=${inputs.dob}`;
    const shareText = `Hi! Please open this link to complete my parental consent form: ${link}`;

    if (navigator.share) {
      navigator
        .share({
          text: shareText,
        })
        .catch((err) => {
          console.log('Share canceled or failed: ', err);
        });
    } else {
      // Fallback to a WhatsApp link
      const whatsappURL = `whatsapp://send?text=${encodeURIComponent(
        shareText
      )}`;
      window.location.href = whatsappURL;
    }
  };

  useEffect(() => {
    let swiper = new Swiper('.events-slider', {
      speed: 800,
      spaceBetween: 17,
      slidesPerView: 1,
      loop: false,
      autoplay: {
        delay: 5000,
      },
    });
  });

  return (
    <div className='fest-event'>
      <div className='container pb-5 '>
        <div className='row'>
          {/* Left Column: Info Banners (desktop only) */}

          {/* swiper containing the cards*/}
          <div className='col-md-5 info-banners order-md-1 order-2'>
            <div class='swiper-container events-slider'>
              <div class='swiper-wrapper'>
                {cards.map((d, i) => {
                  if (i === cards.length - 1) return;

                  return (
                    <div class='swiper-slide' key={i}>
                      <div
                        className={'banner-item mt-5 slider-item-' + i}
                        key={i}>
                        {/* <div className='banner-content '>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: d.content,
                            }}
                          />
                        </div> */}
                        {d.banner && (
                          <BannerComponent value={d.banner} type={1} />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {cards.map((d, i) => {
              if (i !== cards.length - 1) return;
              return (
                <div className={'banner-item my-3 item-' + i} key={i}>
                  <div className='banner-content '>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: d.content,
                      }}
                    />
                  </div>
                  {d.banner && <BannerComponent value={d.banner} type={1} />}
                </div>
              );
            })}
          </div>

          {/* Right Column: Registration Form & Mobile Banners */}
          <div className='col-md-6 order-md-2 order-1'>
            <form
              className='event-form shadow p-4 mt-5'
              onSubmit={handleSubmit}>
              {/* <h3 className='mb-4 text-primary form-title'>
                Registration Closed
              </h3>
              <div className='over-18-popup p-4'>
                <p>Registration is now closed!</p>
                <p>
                  Stay tuned on our socials @SKYGIRLSKE to catch the Live
                  Stream!
                </p>
              </div> */}

              <h3 className='mb-4 text-primary form-title'>REGISTER NOW</h3>

              <div className='row'>
                <div className='form-group mb-3 col-md-6'>
                  <label className='text-primary form-label'>
                    DATE OF BIRTH
                  </label>
                  <div className='date-input'>
                    <input
                      type='date'
                      onChange={(e) => {
                        handleDOBChange(e.target.value);
                      }}
                      className='form-control'
                      required={true}
                    />
                  </div>
                </div>

                <div className='form-group mb-3 col-md-6'>
                  <label className='text-primary form-label'>GENDER</label>
                  <select
                    className='form-control'
                    required
                    onChange={handleGenderChange}>
                    <option value='' hidden>
                      Choose gender
                    </option>
                    <option value='1'>Female</option>
                    <option value='2'>Male</option>
                  </select>
                </div>
              </div>

              <div className='position-relative'>
                {/* <hr /> */}
                {/* If not out-of-range or male => show Register button */}
                {!showAgeLimitPopup && !showGenderPopup && !showShareBlock && (
                  <button className='btn btn-primary mt-3 px-5 btn-round'>
                    Next
                  </button>
                )}
                {/* Over/Under Age Popup */}
                {showAgeLimitPopup && (
                  <div className='over-18-popup p-4'>
                    The SKY program is for teen girls aged 12 to 19.
                  </div>
                )}
                {/* Gender Popup */}
                {showGenderPopup && (
                  <div className='over-18-popup p-4'>
                    The SKY program is for teen girls aged 12 to 19.
                  </div>
                )}

                {/* Month popup telling girls it a birthday event for girls born in april */}
                {showMonthPopup && (
                  <div className='over-18-popup p-4'>
                    The SKY Birthday event is for girls born in April only.
                  </div>
                )}

                {/* Show share block if user is under 18 and clicked "Register Now" */}
                {showShareBlock && (
                  <div className='pt-4 mt-0'>
                    <h5>Parental Consent Needed</h5>
                    <div>
                      Niaje SKY Girl! You need to get permission kutoka your
                      parents or guardian kuattend SKY Fest. Please send them
                      hii link or click the share button.
                    </div>

                    <div className='d-flex flex-column'>
                      {/* Input with the consent link */}
                      <div className='input-group my-3'>
                        <input
                          type='text'
                          className='form-control'
                          value={`${window.location.origin}/sky-hub-parental-consent?dob=${inputs.dob}`}
                          readOnly
                        />
                        <div className='input-group-append'>
                          {/* Copy icon */}
                          <button
                            className='btn btn-outline-primary'
                            type='button'
                            onClick={copyLink}
                            title='Copy Link'>
                            <Copy />
                          </button>
                        </div>
                      </div>

                      {/* Share and copy buttons */}
                      <div className='d-flex flex-row align-items-center'>
                        <button
                          type='button'
                          className='btn btn-outline-primary mr-3'
                          onClick={copyLink}>
                          Copy Link
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary'
                          onClick={shareLink}>
                          Share
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>

            {/* FAQ Section */}
            <div className='fest-faq shadow py-4 px-md-4 px-3 mt-4'>
              <h3 className='mb-4 text-primary form-title'>
                Birthday Event FAQS
              </h3>
              <div className='faqs'>
                {faqs.map((faqItem, i) => (
                  <div
                    key={i}
                    className={
                      'faq-item shadow-sm ' + (activeFAQ === i ? 'active' : '')
                    }
                    onClick={() => setActiveFAQ(i)}>
                    <div className='faq-title d-flex justify-content-between px-3 py-2'>
                      <div>{faqItem.question}</div>
                      <div>
                        {activeFAQ === i ? <ChevronUp /> : <ChevronDown />}
                      </div>
                    </div>
                    <div className='faq-content border-top'>
                      <div className='p-3'>{faqItem.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BirthdayEvent;
