import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, navigate } from '@reach/router'; // or 'gatsby'
import moment from 'moment';

// Custom components
import DocumentUploader from '../DocumentUploader';
import SKYFestLocations from '../SKYFestLocations';
import { MedicalConditionInput } from '../MedicalConditionInput';

function BirthdayConsentForm() {
  // State to track whether user is adult or minor
  const [isAdult, setIsAdult] = useState(null);
  // State for date of birth (from param or user input)
  const [dob, setDob] = useState('');
  const [dobInput, setDobInput] = useState('');
  // For capturing gender
  const [gender, setGender] = useState('');

  // Loader state
  const [loading, setLoading] = useState(false);

  // For toggling form display, especially if dob param isn't provided
  const [showMainForm, setShowMainForm] = useState(false);

  // Scroll detection for minor’s consent text
  const [hasScrolledConsent, setHasScrolledConsent] = useState(true);

  // Form fields
  const [inputs, setInputs] = useState({
    name: '', // Child name if minor; user name if adult
    phone: '', // Child phone if minor; user phone if adult
    parent_name: '',
    phone_parent: '',
    parent_id_number: '',
    document_uri: '',
    location: '',
    lat: null,
    lng: null,
    // Checkboxes
    legalGuardianConfirm: true, // "I confirm that I am the legal guardian..."
    termsAgreed: false, // "By checking this box I agree to the terms above..."
    adultConsent: false, // "I give consent for data processing..." (adults)
  });

  // Check for ?dob= in the URL
  const locationHook = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(locationHook.search);
    let dobParam = params.get('dob');
    if (dobParam) {
      dobParam = moment(dobParam).format('YYYY-MM-DD');
      setDob(dobParam);
      determineAgeGroup(dobParam);
      setShowMainForm(true);
    }
  }, [locationHook.search]);

  // Determine if user is 18+ or under 18
  const determineAgeGroup = (dobValue) => {
    const years = moment().diff(dobValue, 'years');
    setIsAdult(years >= 18);
  };

  // Called if no dob param is present. Once user picks an age, show rest of form
  const handleDOBChange = (e) => {
    setDobInput(e.target.value);
  };

  const handleConfirmDOB = () => {
    if (!dobInput) return alert('Please enter your date of birth.');
    if (!gender) return alert('Please select your gender.');
    setDob(dobInput);
    determineAgeGroup(dobInput);
    setShowMainForm(true);
  };

  // Generic input change handler
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // For checkboxes
    if (type === 'checkbox') {
      setInputs((prev) => ({ ...prev, [name]: checked }));
    } else {
      setInputs((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Called by the DocumentUploader
  const handleDocumentChange = (docUri) => {
    setInputs((prev) => ({ ...prev, document_uri: docUri }));
  };

  // Called by the LocationInput (placeholder in original code)
  const handleLocationChange = ({ location, lat, lng }) => {
    setInputs((prev) => ({ ...prev, location, lat, lng }));
  };

  // Track user scrolling through consent text
  const handleScroll = useCallback((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setHasScrolledConsent(true);
    }
  }, []);

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Prevent multiple submissions
    if (loading) return;

    // Start loader
    setLoading(true);

    try {
      // Basic validation
      if (!inputs.name) throw new Error('Name is required.');
      // For adult, phone is required; for minor, child’s phone is optional
      if (isAdult && !inputs.phone) {
        throw new Error('Phone is required.');
      }
      // If minor, verify parent's info
      if (!isAdult) {
        if (!inputs.parent_name) {
          throw new Error('Parent/Guardian name is required.');
        }
        if (!inputs.phone_parent) {
          throw new Error('Parent/Guardian phone is required.');
        }
        if (!inputs.legalGuardianConfirm) {
          throw new Error('Please confirm you are the legal guardian.');
        }
        if (!inputs.termsAgreed) {
          throw new Error('You must agree to the terms.');
        }
      } else {
        // If adult, ensure doc is uploaded
        if (!inputs.document_uri) {
          throw new Error('Please upload proof of age (ID, birth cert, etc).');
        }
        if (!inputs.adultConsent) {
          throw new Error('You must agree to the terms and conditions.');
        }
      }

      // Prepare payload
      const payload = {
        dob,
        gender,
        name: inputs.name,
        phone: inputs.phone,
        document_uri: inputs.document_uri,
        location: inputs.location,
        lat: inputs.lat,
        lng: inputs.lng,
        medical_condition: inputs.medical_condition || 'None',
        parent_name: isAdult ? null : inputs.parent_name,
        phone_parent: isAdult ? null : inputs.phone_parent,
        parent_id_number: isAdult ? null : inputs.parent_id_number,
        eventId: 5,
        eventSlotId: 31,
      };

      const resp = await fetch(`${global.server}/event-data`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await resp.json();

      if (!resp.ok) {
        // The server returned an error
        throw new Error(data.message || 'Submission failed');
      }

      alert('Submission successful!');
      navigate('/parents');
    } catch (error) {
      console.error('Error submitting:', error);
      alert(error.message || 'An error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container'>
      {/* Step 1: If no ?dob param, ask user to input DOB + gender */}

      {/* 2) Show the main form only if we have a DOB (from param or user entry) */}
      {/* {showMainForm && ( */}
      <form
        onSubmit={handleSubmit}
        className='card p-4 consent-card my-5 col-md-6'>
        {/* Minor Flow */}
        {!isAdult && (
          <>
            <h5 className='mt-3 mb-3' style={{ fontWeight: 'bold' }}>
              Please enter the following information to register your daughter /
              ward for SKY Hub:
            </h5>
            {/* Name of Daughter / ward */}
            <div className='mb-3'>
              <label>Name of Daughter/Ward</label>
              <input
                className='form-control'
                name='name'
                value={inputs.name}
                onChange={handleChange}
                disabled={!hasScrolledConsent}
                required
              />
            </div>

            <div className='mb-3'>
              <label>Date of Birth</label>

              <input
                type='date'
                className='form-control'
                value={dob}
                onChange={(e) => {
                  setDob(e.target.value);
                  determineAgeGroup(e.target.value);
                }}
                disabled={!hasScrolledConsent}
                required
              />
            </div>

            {/* Phone Number of Daughter/Ward (optional) */}
            <div className='mb-3'>
              <label>Phone Number of Daughter/Ward (Optional)</label>
              <input
                type='tel'
                className='form-control'
                name='phone'
                value={inputs.phone}
                onChange={handleChange}
                disabled={!hasScrolledConsent}
              />
            </div>

            {/* "I confirm that I am the legal guardian..." */}
            <div className='form-check mb-3'>
              <input
                type='checkbox'
                className='form-check-input'
                id='legalGuardianConfirm'
                name='legalGuardianConfirm'
                checked={inputs.legalGuardianConfirm}
                onChange={handleChange}
                disabled={!hasScrolledConsent}
                required
                // defaultChecked={true}
              />
              <label
                className='form-check-label'
                htmlFor='legalGuardianConfirm'>
                I confirm that I am the legal guardian of the child mentioned
                above
              </label>
            </div>

            <h5 className='mt-4 mb-3' style={{ fontWeight: 'bold' }}>
              Please enter the following information so we can verify you and
              keep you abreast of your daughter's entry and departure at SKY Hub
              2025:
            </h5>
            {/* Parent Name */}
            <div className='mb-3'>
              <label>Your Name</label>
              <input
                className='form-control'
                name='parent_name'
                value={inputs.parent_name}
                onChange={handleChange}
                required
                disabled={!hasScrolledConsent}
              />
            </div>
            {/* Parent Phone */}
            <div className='mb-3'>
              <label>Your Phone Number</label>
              <input
                type='tel'
                className='form-control'
                name='phone_parent'
                value={inputs.phone_parent}
                onChange={handleChange}
                required
                disabled={!hasScrolledConsent}
              />
            </div>
            {/* Parent ID */}
            <div className='mb-3'>
              <label>Your ID Number</label>
              <input
                className='form-control'
                name='parent_id_number'
                value={inputs.parent_id_number}
                onChange={handleChange}
                required
                disabled={!hasScrolledConsent}
              />
            </div>

            {/* Location Input */}
            <SKYFestLocations
              value={inputs.location}
              onChange={handleChange}
              required={false}
            />

            <MedicalConditionInput
              label='Does your daughter have any medical conditions?'
              onChange={handleChange}
            />

            {/* Terms checkbox */}
            <div className='form-check mb-3'>
              <input
                type='checkbox'
                className='form-check-input'
                id='termsAgreed'
                name='termsAgreed'
                checked={inputs.termsAgreed}
                onChange={handleChange}
                disabled={!hasScrolledConsent}
                required
              />
              <label className='form-check-label' htmlFor='termsAgreed'>
                I agree to the terms above, regarding my daughter/ward attending
                SKY Girls events, use of audiovisual content, and the personal
                information I provide.
              </label>
            </div>
          </>
        )}

        {/* Adult Flow */}
        {isAdult && (
          <>
            <h4 className='mb-3'>Register for SKY Hub</h4>
            <div className='mb-3'>
              <label>Name</label>
              <input
                className='form-control'
                name='name'
                value={inputs.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className='mb-3'>
              <label>Phone</label>
              <input
                type='tel'
                className='form-control'
                name='phone'
                value={inputs.phone}
                onChange={handleChange}
                required
              />
            </div>

            <div className='mb-3'>
              <label>
                Please upload of proof of age ( a Photo of ID, birth
                certificate, etc)
              </label>
              <DocumentUploader
                onChange={handleDocumentChange}
                value={inputs.document_uri}
                required={isAdult}
              />
            </div>

            <SKYFestLocations
              value={inputs.location}
              onChange={handleChange}
              required={false}
            />

            {/* <MedicalConditionInput
                label='Does you have any medical conditions?'
                onChange={handleChange}
              /> */}

            {/* Consent checkbox for adults */}
            <div className='form-check mb-3'>
              <input
                type='checkbox'
                className='form-check-input'
                id='adultConsent'
                name='adultConsent'
                checked={inputs.adultConsent}
                onChange={handleChange}
              />
              <label className='form-check-label' htmlFor='adultConsent'>
                I give consent for the processing of my data and agree to the
                terms
                {/* laid out in the{' '}
                <a
                  href='/release-form'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Terms and Conditions
                </a> */}
              </label>
            </div>
          </>
        )}

        <div className='d-flex align-items-center justify-content-between'>
          <button
            className='btn btn-primary mt-3 px-4'
            type='submit'
            disabled={
              loading ||
              (!isAdult &&
                // For minors, also disable if they haven't scrolled or unchecked required fields
                !hasScrolledConsent)
            }>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
      {/* )} */}
    </div>
  );
}

export default BirthdayConsentForm;
